module.exports = {
  siteTitle: 'Cam\'s Cakes & Other Bakes', // Site title.
  siteTitleAlt: 'Bakery', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://camscakesandbakes.com', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'Making sweet treats for sweeter people', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: 'cams-cakes-and-bakes', // Disqus shortname.
  userName: 'Wade Kaiser',
  userTwitter: 'Muddl_',
  userLocation: 'Georgia, USA',
  userDescription: '',
  copyright: 'Copyright © Gatsby Starter Business 2018-2019. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#00d1b2', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}
